import { isPlatformBrowser } from '@angular/common';
import { DestroyRef, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReplaySubject, tap } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GeoCodeResult, ListingTypeView, ProfileLiteView, UtilityStatusView } from '../../api/models';
import { DataService, GeoService, ProfileService } from '../../api/services';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private authService = inject(AuthService);
    private dataService = inject(DataService);
    private router = inject(Router);
    private profileService = inject(ProfileService);
    private geoService = inject(GeoService);


    private utilityStatusSubject: ReplaySubject<UtilityStatusView[]> = new ReplaySubject<UtilityStatusView[]>(1);
    private utilityStatusLoaded = false;
    public utilityStatus$ = this.utilityStatusSubject.asObservable();

    private geoLocateSubject: ReplaySubject<GeoCodeResult> = new ReplaySubject<GeoCodeResult>(1);
    public geoLocate$ = this.geoLocateSubject.asObservable();
    private geoLocateLoaded = false;

    private myProfileSubject: ReplaySubject<ProfileLiteView> = new ReplaySubject<ProfileLiteView>(1);
    public myProfileLite$ = this.myProfileSubject.asObservable();

    private listingTypesLoaded = false;
    private listingTypesSubject: ReplaySubject<ListingTypeView[]> = new ReplaySubject<ListingTypeView[]>(1);
    public listingTypes$ = this.listingTypesSubject.asObservable();

    private isBrowser = false;

    isAuthenticated = false;
    environment = environment;
    destroyRef = inject(DestroyRef);

    public setListingTypes(listingTypes: ListingTypeView[]) {
        this.listingTypesLoaded = true;
        this.listingTypesSubject.next(listingTypes);
    }

    public geoLocate() {
        if (!this.geoLocateLoaded && this.isBrowser) {
            this.geoLocateLoaded = true;
            this.geoService.locateIp().subscribe(result => {
                this.geoLocateSubject.next(result as GeoCodeResult);
            });
        }
    }

    constructor() {
        const platformId = inject(PLATFORM_ID);

        environment.isLoggingEnabled && console.log(`New Session: ${new Date()}`);

        this.isBrowser = isPlatformBrowser(platformId);
        this.geoLocate();
        this.ensureListingTypesLoaded();
        this.ensureUtilityStatusLoaded();

        this.authService.isAuthenticated().pipe(
            tap(result => this.isAuthenticated = result),
            filter(result => result),
            tap(() => this.reloadProfile('sessionService1')),
        ).subscribe();

        this.authService.authChanged.pipe(
            switchMap(() => this.authService.isAuthenticated()),
            tap(result => this.isAuthenticated = result),
            filter(result => result),
            tap(() => this.reloadProfile()),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe();
    }

    hasValidAccessToken(): boolean {
        return this.isAuthenticated;
    }

    ensureListingTypesLoaded() {
        if (!this.listingTypesLoaded) {
            this.listingTypesLoaded = true;
            this.dataService.getListingTypes().pipe(
                tap(result => this.listingTypesSubject.next(result)),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe();
        }
    }

    ensureUtilityStatusLoaded() {
        if (!this.utilityStatusLoaded) {
            this.utilityStatusLoaded = true;
            this.dataService.getUtilityStatus().pipe(
                tap(result => this.utilityStatusSubject.next(result)),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe();
        }
    }

    reloadProfile(source?: String) {
        if (!environment.production) {
            console.log('reloadProfile', source);
        }
        var profile = this.authService.profile;
        this.myProfileSubject.next(this.authService.profile());
    }

    login() {
        this.router.navigate(['/login']);
    }

    async logout() {
        this.router.navigate(['/logout']);
    }

    editProfile() {

    }

}
