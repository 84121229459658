{
  "name": "rentability",
  "version": "2.18.0",
  "scripts": {
    "ng": "ng",
    "prestart": "node aspnetcore-https",
    "start": "run-script-os",
    "start:windows": "ng serve --port 4200 --ssl --ssl-cert %APPDATA%\\ASP.NET\\https\\%npm_package_name%.pem --ssl-key %APPDATA%\\ASP.NET\\https\\%npm_package_name%.key",
    "start:default": "ng serve --port 4200 --ssl --ssl-cert $HOME/.aspnet/https/${npm_package_name}.pem --ssl-key $HOME/.aspnet/https/${npm_package_name}.key",
    "lint": "ng lint",
    "build": "ng build --configuration production",
    "build-test": "ng build -c=test",
    "stats": "ng build --stats-json && webpack-bundle-analyzer ./dist/stats.json"
  },
  "engines": {
    "node": ">=20.0.0"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.1",
    "@angular/common": "^19.0.1",
    "@angular/compiler": "^19.0.1",
    "@angular/core": "^19.0.1",
    "@angular/forms": "^19.0.1",
    "@angular/google-maps": "^19.0.1",
    "@angular/platform-browser": "^19.0.1",
    "@angular/platform-browser-dynamic": "^19.0.1",
    "@angular/router": "^19.0.1",
    "@angular/youtube-player": "^19.0.1",
    "@auth0/angular-jwt": "^5.2.0",
    "@fortawesome/angular-fontawesome": "^1.0",
    "@fortawesome/fontawesome-pro-duotone": "file:node_modules_local/fortawesome-pro-duotone-svg-icons-5.15.4.tgz",
    "@fortawesome/fontawesome-pro-light": "file:node_modules_local/fortawesome-pro-light-svg-icons-5.15.4.tgz",
    "@fortawesome/fontawesome-pro-regular": "file:node_modules_local/fortawesome-pro-regular-svg-icons-5.15.4.tgz",
    "@fortawesome/fontawesome-pro-solid": "file:node_modules_local/fortawesome-pro-solid-svg-icons-5.15.4.tgz",
    "@fortawesome/fontawesome-svg-core": "file:node_modules_local/fortawesome-fontawesome-svg-core-1.2.36.tgz",
    "@microsoft/applicationinsights-web": "^3.3.4",
    "@ng-select/ng-select": "^14.1.0",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@tinymce/tinymce-angular": "^8.0.1",
    "bootstrap": "^5.3.3",
    "localstorage-polyfill": "^1.0.1",
    "ngx-bootstrap": "^18.1.3",
    "ngx-clipboard": "^16.0.0",
    "rxjs": "~7.8.1",
    "sweetalert2": "^11.14.5",
    "tinymce": "^7.5.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.2",
    "@angular-devkit/core": "~19.0.2",
    "@angular-devkit/schematics": "~19.0.2",
    "@angular/cli": "~19.0.2",
    "@angular/compiler-cli": "^19.0.1",
    "@schematics/angular": "~19.0.2",
    "@types/jasmine": "~5.1.5",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.4"
  }
}
